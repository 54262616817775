function toggleMobileMenu () {
  const rootElement = document.getElementById('mobile-navigation-root')
  if (rootElement?.classList.contains('opacity-0')) {
    rootElement.classList.remove('opacity-0')
    rootElement.classList.add('opacity-100')
  } else {
    rootElement?.classList.remove('opacity-100')
    rootElement?.classList.add('opacity-0')
  }
  if (rootElement?.classList.contains('invisible')) {
    rootElement.classList.remove('invisible')
    rootElement.classList.add('visible')
  } else {
    setTimeout(function () {
      rootElement?.classList.remove('visible')
      rootElement?.classList.add('invisible')
    }, 300)
  }

  document.getElementById('mobile-menu-button-open')?.classList.toggle('hidden')
  document.getElementById('mobile-menu-button-close')?.classList.toggle('hidden')
}

type WindowWithToggleMobileMenu = Window & {
  toggleMobileMenu: () => void
}

(window as unknown as WindowWithToggleMobileMenu).toggleMobileMenu = toggleMobileMenu
